<template>
    <a-modal :visible="value" @cancel="handleClose" title="批量删除"   width="700px" :maskClosable="false">
        <a-alert :message="`是否将以下 ${list.length} 个帖子移出课题组？`" banner />
        <a-table class="delete-table" :columns="tableColumns" :border="false" rowKey="paperId" :data-source="list" :pagination="false" style="margin-top: 16px;" size="small" :scroll="{ y: 300 }">
            <template slot="postTitle" slot-scope="text, record, index">
                <div class="post-title">
                    <a :href="getPostHref(record.paperId)" target="_blank">
                        {{ record.title }}
                    </a>
                </div>
                <div class="publish-time">
                    {{ record.publishedTime }}
                </div>
            </template>
            <template slot="creator" slot-scope="text, record, index">
                <div>
                    <div class="user-name">
                        {{ record.userName }}
                    </div>
                </div>
            </template>
        </a-table>
        <template slot="footer">
            <div class="modal-footer">
                <a-button :disabled="isConfirmLoading" :loading="isConfirmLoading" type="primary" @click="handleConfirm"> 确定 </a-button>
                <a-button :disabled="isConfirmLoading" @click="handleClose" > 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
a {
    text-decoration: none;
    color: inherit;
}
.delete-table {
    .post-title {
        color: rgba(0, 0, 0, 0.85);

        &:hover {
            text-decoration: underline;
        }
    }

    .publish-time {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 4px;
    }

    .user-name {
        color: rgba(0, 0, 0, 0.65);
    }
}

.modal-footer {
    text-align: center;
}
</style>

<script>
const tableColumns = [
    {
        title: '标题',
        dataIndex: "",
        flex: 1,
        scopedSlots: { customRender: "postTitle" }
    },
    {
        title: '楼主',
        dataIndex: "creator",
        width: '150px',
        scopedSlots: { customRender: "creator" }
    },
]
import { removePaperGroup } from '@/api/researchGroupPaperManage.js';
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            tableColumns,
            isConfirmLoading: false,
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:value', false);
        },
        async handleConfirm() {
            this.isConfirmLoading = true;
            const formData = new FormData();
            formData.append('paperid',this.list.map(post => post.paperId) )
            formData.append('groupid', this.$route.query.id);
            const res = await removePaperGroup(formData);
            if(res.success) {
                this.$emit('onBulkRemove')
            }
            /* setTimeout(() => {
                this.isConfirmLoading = false;
                this.handleClose();
            },2000) */
        },
        getPostHref(id) {
            const href = this.$router.resolve({
                name: 'bbsPost',
                params: {
                    id: id
                }
            })
            return href.href
        }
    }
}
</script>