<!-- 课题组帖子板块 -->
<template>
    <div class="research-team-ctn">
        <div class="breadcrumb-ctn">
            <a-breadcrumb>
                <a-breadcrumb-item v-for="(route, index) in breadcrumbData" :key="route.path">
                    <router-link v-if="route.path" :to="route.path">
                        {{ route.breadcrumbName }}
                    </router-link>
                    <span v-else>
                        {{ route.breadcrumbName }}
                    </span>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="main-content">
            <div class="left-content post-content">
                <a-tabs v-model="activeTab">
                    <!-- <template slot="tabBarExtraContent">
                        <div v-if="!isManage" style="height: 48px;">
                            <div class="order-component" :style="{ '--left': orderBeforeLeft }">
                                <div :class="['order-item', 'order-hot', currentOrder === 'hot' ? 'active-order' : '']"
                                    @click="handleHotClick">
                                    热度
                                </div>
                                <a-tooltip :title="timeOrderTooltip">
                                    <div :class="['order-item', 'order-time', currentOrder === 'time' ? 'active-order' : '']"
                                        @click="handleTimeOrderClick">
                                        时间
                                        <div
                                            style="display: flex; flex-direction: column;margin-left: 3px; justify-content: center;">
                                            <svg-icon name="bbs-sort-arrow-up"
                                                :class="['sort-icon', currentOrder === 'time' && isIncrease ? 'active-order-icon' : '']"></svg-icon>
                                            <svg-icon name="bbs-sort-arrow-down"
                                                :class="['sort-icon', currentOrder === 'time' && !isIncrease ? 'active-order-icon' : '']"></svg-icon>
                                        </div>
                                    </div>
                                </a-tooltip>
                            </div>
                        </div>
                        <div v-else>
                            <div v-show="selectedRows.length != 0" class="bulk-delete" style="padding: 0px 16px; margin-top: 5px; margin-right: 10px;">
                                <a-badge :count="selectedRows.length">
                                    <a-button class="bulk-btn" style="border: none; " size="small" @click="handleBulkDeleteButtonClick">
                                        <span style="display: flex;align-items: center;">
                                            <svg-icon class="bulk-delete-icon" name="trash-2" style="color: inherit; fill: inherit;"></svg-icon>
                                            <span style="margin-left: 4px;">
                                                批量移除
                                            </span>
                                        </span>
                                    </a-button>
                                </a-badge>
                            </div>
                        </div>
                    </template> -->
                    <a-tab-pane key="all" tab="全部帖子">
                        <div v-if="isManage">
                            <a-table class="manage-table" rowKey="paperId" :columns="manageTableColumns" :border="false"
                                :data-source="list" :pagination="false" :loading="listLoading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }">
                                <template slot="postTitle" slot-scope="text, record, index" >
                                    <div class="post-title">
                                        <a :href="getPostHref(record.paperId)" target="_blank" @click.stop="() => {}">
                                            {{  record.title  }}
                                        </a>
                                    </div>
                                    <div class="publish-time">
                                        {{  record.publishedTime  }}
                                    </div>
                                </template>
                                <template slot="creator" slot-scope="text, record, index">
                                    <div>
                                        <div class="user-name">
                                            {{  record.userName  }}
                                        </div>
                                    </div>
                                </template>
                                <template slot="action" slot-scope="text, record, index">
                                    <div>
                                        <a-button type="link" @click="handleGoPost(record)"> 查看 </a-button>
                                        <a-button type="link" class="remove-btn" @click="handleDelete(record)"> 移除 </a-button>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                        <div v-else class="post-list">
                            <!-- <a-table rowKey="experimentId"  :row-selection=" { selectedRowKeys: selectedRowKeys, onChange: onSelectionChange,getCheckboxProps: getCheckboxProps, fixed:true } " :custom-row="customRow" :columns="allColumns"
								:data-source="list" :pagination="false" size="small" class="exper-table"
								:scroll="{ y: tableHeight, x: 1100 }" :loading="tableLoading" > -->
                            <a-table class="normal-table" rowKey="paperId" :columns="tableColumns" :border="false" :data-source="list"
                                :pagination="false" :customRow="getNormalCustomRow" :loading="listLoading" >
                                <template slot="postTitle" slot-scope="text, record, index">
                                    <div class="post-title">
                                        <a :href="getPostHref(record.paperId)" target="_blank" @click.stop="() => {}">
                                            {{  record.title  }}
                                        </a>
                                    </div>
                                </template>
                                <template slot="creator" slot-scope="text, record, index">
                                    <div>
                                        <div class="user-name">
                                            {{  record.userName  }}
                                        </div>
                                        <div class="publish-time">
                                            {{  record.publishedTime  }}
                                        </div>
                                    </div>
                                </template>

                            </a-table>

                        </div>
                        <!-- <div class="pagination-ctn default-pagination">
                            <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize"
                                :total="total" show-quick-jumper hideOnSinglePage />
                        </div> -->
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="right-content">
                <ResearchTeamInfoSideCard :groupData="researchGroupInfo"></ResearchTeamInfoSideCard>
                <div class="relative-post-ctn">
                    <SideGapCard gapColor="#FF622D" title="课题组热帖">
                        <template>
                            <a-skeleton :loading="memberLoading && hotPostList.length === 0" active :title="false"
                                :paragraph="{ rows: 10, width: '100%' }"
                                :style="{ padding: memberLoading && hotPostList.length === 0 ? '10px' : '' }">
                                <div v-show="!isHotPostListLoading && hotPostList.length > 0" class="post-list">
                                    <div v-for="(item, index) in hotPostList" class="post-item">
                                        <div :class="['post-item-dot', `post-item-dot-${index}`]">
                                        </div>
                                        <a class="post-item-text" :href="getPostHref(item.paperId)" target="_blank"
                                            :title="item.title">
                                            {{ item.title }}
                                        </a>
                                    </div>
                                </div>
                                <a-empty v-if="!isHotPostListLoading && hotPostList.length === 0"
                                    style="margin: 16px 10px; " />
                            </a-skeleton>
                        </template>
                    </SideGapCard>
                </div>
                <div v-if="isManage" id="research-team-member-card">
                    <div class="card-title">
                        <!-- <div class="card-title-icon"> -->
                        <svg-icon name="group" class="title-icon"></svg-icon>
                        <!-- </div> -->
                        <div class="card-title-text">
                            课题组成员
                        </div>
                    </div>
                    <div class="card-content">
                        <a-skeleton v-show="memberLoading && memberList.length === 0" active avatar title
                            :paragraph="{ rows: 2 }" style="padding: 16px 10px;" />
                        <a-spin v-show="memberList.length > 0" :spinning="memberLoading" tip="正在加载">
                            <div class="member-list">
                                <div v-for="(member, index) in memberList" class="member-outside-ctn">
                                    <MemberItem :index="index" :userData="member"></MemberItem>
                                </div>
                            </div>
                        </a-spin>
                        <a-empty v-if="!memberLoading && memberList.length === 0" style="margin: 16px 10px; " />
                        <div class="pagination-ctn default-pagination">
                            <a-pagination size="small" @change="handleMemberListPageChange" v-model="memberListCurrent"
                                :pageSize="memberListPagesize" :total="totalMember" hideOnSinglePage show-less-items />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-back-top />
        <BulkDeleteModal v-model="bulkDeleteModalVisible" :list="selectedRows" @update:value="(val) => bulkDeleteModalVisible = val"  @onBulkRemove="handleBulkDeleteCallBack"></BulkDeleteModal>
    </div>
</template>

<script>
import { computed, h, onBeforeMount, onMounted, ref } from 'vue-demi';
import ResearchTeamInfoSideCard from '../components/researchTeamInfoSideCard.vue';
import SideGapCard from '../components/sideGapCard.vue';
import BulkDeleteModal from './components/BulkDeleteModal.vue';
import MemberItem from './components/memberItem.vue';
import { getGroupPaperList, getGroupPaperHotTopList, removePaperGroup,getGroupUser } from '@/api/researchGroupPaperManage.js';
import { getGroupPostInfo ,deletePaper} from '@/api/paperManage.js';
import { message, Modal } from 'ant-design-vue';

const tableColumns = [
    {
        title: '',
        dataIndex: "",
        flex: 1,
        scopedSlots: { customRender: "postTitle" }
    },
    {
        title: '楼主',
        dataIndex: "creator",
        scopedSlots: { customRender: "creator" }
    },
    {
        title: '评论',
        dataIndex: "commitCount",
        /* width: 180,
        fixed: "right", */
        scopedSlots: { customRender: "comment" }
    },
    {
        title: '喜欢',
        dataIndex: "likeCount",
        scopedSlots: { customRender: "like" }
    }
]

const manageTableColumns = [
    {
        title: '',
        dataIndex: "",
        flex: 1,
        scopedSlots: { customRender: "postTitle" }
    },
    {
        title: '楼主',
        dataIndex: "creator",
        scopedSlots: { customRender: "creator" }
    },
    {
        title: '评论',
        dataIndex: "commitCount",
        /* width: 180,
        fixed: "right", */
        scopedSlots: { customRender: "comment" }
    },
    {
        title: '喜欢',
        dataIndex: "likeCount",
        scopedSlots: { customRender: "like" }
    },
    {
        title: '操作',
        width: 180,
        fixed: "right",
        scopedSlots: { customRender: 'action' }
    }
]

export default {
    setup(props, { root }) {
        const breadcrumbData = computed(() => {
            const res = [
                {
                    path: "/bbs/index",
                    breadcrumbName: "论坛首页"
                },
            ]
            if(researchGroupInfo.value) {
                res.push({
                    path: "",
                    breadcrumbName: researchGroupInfo.value.groupName
                })
            }
            return res;
        });
        const { $route, $router } = root;
        const activeTab = ref("all"); // 当前活跃的tab标签
        const isManage = ref($route.query.mode == 'manage'); // 是否是管理员
        /*******************************            排序控制             *******************************/
        const currentOrder = ref("hot"); // 当前顺序： 热度| 时间
        const isIncrease = ref(false); // 是否是升序
        const orderBeforeLeft = computed(() => {
            if (currentOrder.value == "hot") {
                return "3px";
            }
            else if (currentOrder.value == "time") {
                return "58px";
            }
        });
        // 时间排序提示信息
        const timeOrderTooltip = computed(() => {
            if (currentOrder.value != "time") {
                return null;
            }
            else {
                if (isIncrease.value) {
                    return "最先发布";
                }
                else {
                    return "最晚发布";
                }
            }
        });
        // 点击时间排序
        const handleTimeOrderClick = () => {
            if (currentOrder.value === "hot") {
                currentOrder.value = "time";
                isIncrease.value = false;
            }
            else if (currentOrder.value) {
                isIncrease.value = !isIncrease.value;
            }
        };
        // 
        const handleHotClick = () => {
            currentOrder.value = "hot";
        };
        /*******************************            排序控制             *******************************/
        const list = ref([]); // 列表
        const listLoading = ref(true); // 是否正在加载列表

        const getList = async () => {
            listLoading.value = true;
            const res = await getGroupPaperList({
                groupid: $route.query.id,
                pageno: current.value,
                pagesize : pageSize.value
            })
            if(res.success) {
                list.value = res.data.data;
                total.value = res.data.count
            }
            listLoading.value = false;
        }

        onMounted(() => {
            getList()
            /* list.value.splice(list.value.length, 0, ...[]); */
        });
        // 获取普通用户（非管理员权限的同户行点击事件）
        const getNormalCustomRow = (record) => {
            return {
                on: {
                    click: (event) => {
                        window.open(getPostHref(record.paperId), "_blank");
                    }
                }
            };
        };
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(20); // 单页数目
        // 页数改变
        const handlePageChange = () => {
            listLoading.value = true;
             // 将已选行中取消选择的脏数据去除
             selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            setTimeout(() => {
                index.value = (current.value - 1) * 20;
                const arr = [];
                for(let i = index.value; i < index.value + 20; ++i) {
                    arr.push({
                        id: i
                    })
                }
                list.value = arr
                listLoading.value = false;
            }, 2000);
        };

        const index = ref(0)  // 用于模拟输入

        

        onBeforeMount(() => {
            initGroupInfo();
            /* setTimeout(() => {
                memberLoading.value = false;
                const arr = [];
                for(let i = index.value; i < index.value + 10; ++i) {
                    arr.push({
                        id: i
                    })
                }
                index.value = index.value + 10;
                memberList.value = arr;
            }, 3000); */
            handleMemberListPageChange();
            initHotPostList();
        });

        const memberLoading = ref(true);    // 课题组成员是否正在加载

        const memberList = ref([]);     // 课题组成员列表

        const memberListCurrent = ref(1);   // 课题组成员列表

        const totalMember = ref(0);     // 课题组成员数量

        const memberListPagesize = ref(10);     // 课题组成员单页数量

        // 课题组页数切换
        const handleMemberListPageChange = async () => {
            memberLoading.value = true;
            const res = await getGroupUser({
                groupid: $route.query.id,
                pagesize: memberListPagesize.value,
                pagenum: memberListCurrent.value
            })
            if(res.success) {
                memberList.value = res.data.rows;
                totalMember.value = res.data.totalRows;
            }
            memberLoading.value = false;
           /*  setTimeout(() => {
                memberLoading.value = false;
            }, 3000) */
        }

        /**************************              课题组热帖            ***************************/
        const isHotPostListLoading = ref(true);

        const hotPostList = ref([]);

        const initHotPostList = async () => {
            isHotPostListLoading.value = true;
            const res = await getGroupPaperHotTopList({
                groupid: $route.query.id
            });
            hotPostList.value = res.data;
            isHotPostListLoading.value = false;
            /* setTimeout(() => {
                hotPostList.value.splice(0, 0, ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                isHotPostListLoading.value = false;
            }, 2000) */
        }

        // ##### 批量删除
        const bulkDeleteModalVisible = ref(false);

        // 批量删除按钮点击
        const handleBulkDeleteButtonClick = () => {
            bulkDeleteModalVisible.value = true;
        }

        // 批量删除后的回调
        const handleBulkDeleteCallBack = () => {
            getList();
            bulkDeleteModalVisible.value = false;
            selectedRowKeys.value = [];
            selectedRows.value = []
        }

        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.paperId);
            const unselectedRows = list.value.filter(item =>  rowKeys.indexOf(item.paperId) == -1);
            const unselectedRowKeys = unselectedRows.map(item => item.paperId);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.paperId) === -1) {
                    selectedRows.value.push(element);
                }
            });

            selectedRows.value = selectedRows.value.filter(item => unselectedRowKeys.indexOf(item.paperId) == -1)
            

            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        };
        // 是否可以选择
        const getCheckboxProps = (record) => {
            return {
                props: {
                    disabled: false
                }
            };
        };

        const getPostHref = (id) => {
            const href = $router.resolve({
                name: 'bbsPost',
                params: {
                    id: id
                }
            });
            return href.href;
        }

        const researchGroupInfo = ref(null);

        const initGroupInfo = async () => {
            const res = await getGroupPostInfo({
                groupid: $route.query.id
            });
            if(res.success) {
                researchGroupInfo.value = res.data;
                document.title = `${researchGroupInfo.value.groupName} - 课题组`
            }
        }

        const handleGoPost = (record) =>  {
            window.open(getPostHref(record.paperId), "_blank");
        }

        // 处理删除
        const handleDelete = (record) => {
            Modal.confirm({
                title: '提示',
                content: h('span', { }, [
                    '是否确认把文章',
                    h('span', { style: {  color: '#1890ff' } }, record.title),
                    '从课题组中移除? '
                ] ),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('paperid',[record.paperId] )
                    formData.append('groupid', $route.query.id);
                    const res =  await removePaperGroup(formData)
                    if(res.success) {
                        message.success('成功移除');
                        getList();
                    }
                }
            })
        }

        return {
            breadcrumbData,
            activeTab,
            isManage,
            orderBeforeLeft,
            timeOrderTooltip,
            handleTimeOrderClick,
            currentOrder,
            handleHotClick,
            isIncrease,
            tableColumns,
            list,
            getNormalCustomRow,
            current,
            total,
            pageSize,
            handlePageChange,
            listLoading,
            memberLoading,
            memberList,
            memberListCurrent,
            totalMember,
            handleMemberListPageChange,
            memberListPagesize,
            isHotPostListLoading,
            hotPostList,
            manageTableColumns,
            bulkDeleteModalVisible,
            handleBulkDeleteCallBack,
            selectedRowKeys,
            selectedRows,
            onSelectChange,
            getCheckboxProps,
            handleBulkDeleteButtonClick,
            getPostHref,
            researchGroupInfo,
            handleGoPost,
            handleDelete
        };
    },
    components: { ResearchTeamInfoSideCard, SideGapCard, MemberItem, BulkDeleteModal }
}
</script>

<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: none;
}

.breadcrumb-ctn {
    margin-top: 12px;
    //width: calc(100% - 300px);
}

.research-team-ctn {
    .main-content {
        margin-top: 20px;
        display: flex;
        margin-bottom: 80px;

        .left-content {
            flex: 1;
            width: 0;
            background: #fff;
            border: .5px solid #EBEBEB;

            .order-component {
                display: flex;
                align-items: center;
                padding: 3px;
                /* --left: 3px; */
                position: relative;
                z-index: 300;
                height: 30px;
                margin-top: 7px;
                margin-right: 10px;
                border-radius: 2px;

                &::before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    content: '';
                    background: #F6F6F6;
                    z-index: -100;
                }

                &::after {
                    position: absolute;
                    width: 55px;
                    height: calc(100% - 6px);
                    left: var(--left);
                    top: 3px;
                    content: '';
                    background: #fff;
                    z-index: -100;
                    transition: .3s left ease-in-out;
                }

                .order-item {
                    width: 55px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.65);
                    transition: .3s all ease-in-out;

                    &:hover {
                        opacity: 0.8;
                        color: @srims-primary-color;
                    }

                    .sort-icon {
                        fill: rgba(0, 0, 0, 0.65);
                        font-size: 7px;
                    }

                    .active-order-icon {
                        fill: @srims-primary-color;
                    }
                }

                .active-order {
                    color: rgba(0, 0, 0, 0.85);
                }
            }

            /deep/ .ant-tabs-nav-scroll {
                margin-left: 20px;
            }

            /deep/ .ant-tabs-nav .ant-tabs-tab {
                padding: 14px 14px;

                &:hover {
                    color: @srims-primary-color;
                }
            }

            /deep/ .ant-tabs-ink-bar {
                background-color: @srims-primary-color;
                // transform: scaleX(0.5) !important;
            }

            /deep/ .ant-tabs-nav .ant-tabs-tab-active {
                color: @srims-primary-color;
            }

            /deep/ .ant-table-thead {
                th {
                    background: transparent;
                }
            }

            /deep/ .normal-table {
                .ant-table-row {
                    cursor: pointer;
                }
            }

            .manage-table {
                .remove-btn {
                    color: rgba(0, 0, 0, 0.45);

                    //transition: .3s all ease-in-out;
                    &:hover {
                        color: #1890ff;
                    }
                }
            }

            .post-title {
                    color: rgba(0, 0, 0, 0.85);

                    &:hover {
                        text-decoration: underline;
                    }
                }

            .publish-time {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.45);
                margin-top: 4px;
            }

            .user-name {
                color: rgba(0, 0, 0, 0.65);
            }

            .bulk-btn {
                .bulk-delete-icon {
                    transition: .3s all ease-in-out;
                }
                &:hover, &:active, &:visited, &:focus {
                    .bulk-delete-icon {
                        fill: @srims-primary-color !important;       
                    }
                }
            }


            .pagination-ctn {
                padding: 25px;
                text-align: center;
            }
        }

        .right-content {
            width: 300px;
            margin-left: 30px;

            .relative-post-ctn {
                margin-top: 20px;

                /deep/ .side-gap-card-title {
                    font-weight: bold;
                }

                .post-list {
                    .post-item {
                        display: flex;
                        align-items: center;

                        .post-item-dot {
                            height: 6px;
                            width: 6px;
                            background: rgba(0, 0, 0, 0.45);
                            border-radius: 50%;
                        }

                        .post-item-dot-0 {
                            background: #FF4D4F;
                        }

                        .post-item-dot-1 {
                            background: #FF8615;
                        }

                        .post-item-dot-2 {
                            background: #FFBF41;
                        }

                        .post-item-text {
                            color: rgba(0, 0, 0, 0.65);
                            margin-left: 8px;
                            transition: .3s color ease-in-out;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;

                            &:hover {
                                color: #1890ff;
                                transition: .3s color ease-in-out;
                            }
                        }
                    }

                    .post-item+.post-item {
                        margin-top: 16px;
                    }
                }
            }

            #research-team-member-card {
                margin-top: 20px;
                background: #fff;
                border: .5px solid #EBEBEB;

                .member-outside-ctn:last-child {
                    /deep/ .member-item-ctn:last-child {
                        border-bottom: none;
                    }
                }

                .card-title {
                    padding: 14px 16px;
                    display: flex;
                    align-items: center;
                    border-bottom: .5px solid #EBEBEB;

                    .title-icon {
                        fill: @srims-primary-color;
                        font-size: 24px;
                    }

                    .card-title-text {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 16px;
                        margin-left: 5px;
                        font-weight: bold;
                    }
                }

                .member-list {
                    max-height: calc(100vh - 200px);
                }

                .pagination-ctn {
                    text-align: center;
                    padding: 20px 10px;
                }
            }
        }
    }

}
</style>