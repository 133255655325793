// 课题组文章管理
// 论坛模块 接口
import  request from "../utils/request";

// 获取首页数据
export const getIndexPaperList = (params) => {
    return request({
        method: 'get',
        url: '/researchgrouppapermanage/homequery',
        params
    })
}

// 获取课题组列表
export const getGroupPaperList = (params) => {
    return request({
        method: 'get',
        url: '/researchgrouppapermanage/getgrouppaper',
        params
    })
}

// 获取课题组前十热帖
export const getGroupPaperHotTopList = (params) => {
    return request({
        method: 'get',
        url: '/researchgrouppapermanage/getgrouphotpaper',
        params
    })
}

// 获取课题组成员
export const getGroupUser = (params) => {
    return request({
        method: 'get',  
        url: '/researchgrouppapermanage/getgroupusers',
        params
    })
}

// 移除课题组标签
export const removePaperGroup = (data) => {
    return request({
        method: 'post',
        url: '/researchgrouppapermanage/removepapergroup',
        data
    })
}

// 搜索帖子
export const getQueryPaper = (data) => {
    return request({
        method: 'post',
        url: '/researchgrouppapermanage/queryPaper',
        data
    })
}