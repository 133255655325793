<template>
    <div class="research-team-info-side-card-ctn" style="width: 100%;">
        <div class="base-info-ctn">
            <div>
               <ResearchGroupIcon :name="groupData && groupData.groupName"></ResearchGroupIcon>

            </div>
            <div class="base-info">
                <div class="research-team-name">
                    {{ !groupData ? '' : groupData.groupName }}
                </div>
                <div class="today-update">
                    今日更新： <a-icon v-if="!groupData" type="loading" /> <span v-else> {{ groupData.publishedPaperCount
                    }}</span>
                </div>
            </div>
        </div>
        <div class="post-member-count">
            <div class="post-count">
                <div class="info-title">
                    总贴数
                </div>
                <div class="info-count" :title="groupData && groupData.allPaperCount">
                    <span v-if="!groupData">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ groupData.allPaperCount }}
                    </span>
                </div>
            </div>
            <div class="member-count">
                <div class="info-title">
                    人数
                </div>
                <div class="info-count" :title="groupData && groupData.userCount">
                    <span v-if="!groupData">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ groupData.userCount }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.research-team-info-side-card-ctn {
    border: 0.5px solid #EBEBEB;
    background: #fff;

    .base-info-ctn {
        padding: 20px;
        display: flex;

        .research-team-icon {
            object-fit: cover;
            width: 54px;
            height: 54px;
        }

        .base-info {
            margin-left: 8px;

            .research-team-name {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
            }

            .today-update {
                margin-top: 4px;
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .post-member-count {
        display: flex;
        border-top: .5px solid #EBEBEB;

        .post-count,
        .member-count {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 12px;
            width: 50%;

            .info-title {
                color: rgba(0, 0, 0, 0.45);
                font-size: 14px;
            }

            .info-count {
                width: 100%;
                color: #4E5969;
                font-size: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: bold;
                text-align: center;
            }
        }

        .post-count {
            border-right: .5px solid #EBEBEB;
        }
    }
}
</style>

<script>
import ResearchGroupIcon from './researchGroupIcon.vue';

export default {
    props: {
        groupData: {
            default: null
        }
    },
    components: { ResearchGroupIcon }
}
</script>