<template>
    <div class="member-item-ctn">
        <div class="avatar-ctn">
            <a :href="getUserHref" target="_blank">
                <Avatar :url="userData.userAvatar" :name="userData.userName"></Avatar>
            </a>
        </div>
        <div class="member-info-ctn">
            <div class="base-info-ctn">
                <div class="user-name">
                    {{ userData.userName }}
                </div>
                <!-- <div class="user-pos">
                    {{ index % 2 === 0 ? '学生' : '科室主任' }}
                </div> -->
                <div class="user-intro" :title="userData.biography">
                    {{ userData.biography }}
                </div>
            </div>
            <div class="info-items">
                <div class="info-item">
                    文章 ({{  userData.articles  }})
                </div>
                <div class="info-item">
                    粉丝 ({{  userData.followUsers  }})
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: none;
}

.member-item-ctn {
    display: flex;
    padding: 12px;
    border-bottom: .5px solid #EBEBEB;
    transition: .3s background ease-in-out;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    .member-info-ctn {
        margin-left: 8px;
        width: 0px;
        flex: 1;

        .base-info-ctn {
            display: flex;
            align-items: center;
            .user-name {
                color: rgba(0, 0, 0, 0.65);
                white-space: nowrap;
            }

            .user-pos {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.45);
                padding: 0px 8px;
                margin-left: 8px;
                position: relative;
                z-index: 100;
                white-space: nowrap;
                height: 20px;

                &:before {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 8px;
                    top: 4px;
                    left: 0px;
                    z-index: 10;
                    border-left: 0.5px solid #8590A6;
                    border-top: 0.5px solid #8590A6;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 8px;
                    bottom: 4px;
                    right: 0px;
                    z-index: 10;
                    border-right: 0.5px solid #8590A6;
                    border-bottom: 0.5px solid #8590A6;
                }
            }
            .user-intro {
                width: 0;
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-left: 8px;
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
            }
        }
        .info-items {
            display: flex;
            .info-item {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                min-width: 68px;
            }
            .info-item + .info-item {
                margin-left: 16px;
            }
        }
    }
}
</style>

<script>
import { computed } from 'vue-demi';
import Avatar from '../../components/avatar.vue';
export default {
    props: {
        index: {
            default: -1
        },
        userData: {
            default: () => ({})
        }
    },
    components: {
        Avatar
    },
    setup(props, { root }) {
        const getUserHref = computed(() => {
            const { $router } = root;
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: props.userData.userId
                }
            })
            return href.href
        })
        
        return {
            getUserHref
        }
    }
}
</script>